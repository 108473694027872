import {useConfirm} from "material-ui-confirm";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export interface IProps {
    title: string;
    description: string;
    action: () => Promise<any>;
}

export function Confirm({ title, description, action }: IProps) {
    const confirm = useConfirm();
    const { t } = useTranslation();

    useEffect(() => {
        confirm({ title: t(title), description: t(description) })
            .then(action)
            .catch(() => null);
    }, [t, confirm, title, description, action]);

    return <></>
}