import React from 'react';
import Dropzone from 'react-dropzone'
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBCheckbox, MDBInput, MDBTooltip } from 'mdb-react-ui-kit'
import Loader from '../component/loader';
import ContactSelect from '../component/select/contact';
import DealSelect from '../component/select/deal';
import UserSelect from '../component/select/user';
import SignPosition from '../component/signPosition';
import FieldErrors from '../component/fieldError';
import PageTitle from '../component/pageTitle';
import ButtonLoader from '../component/buttonLoader';
import { AppContext } from '../context/app.context';
import { DataStorage } from '../enum/dataStorage';
import { DocumentStatus } from '../enum/documentStatus';
import { Rest } from '../rest';
import Utils from '../utils';
import Deal from '../models/deal';
import { LogType } from '../enum/logType';
import { ObjectType } from '../enum/objectType';
import LogService from '../service/logService';
import { FileType } from '../enum/fileType';
import Log from '../models/log';
import { AccessKey } from '../enum/accessKey';
import ValidationError from '../service/fieldValidator/validationError';
import FieldValidator from '../service/fieldValidator/fieldValidator';
import i18n from '../i18n';
import { withSnackbar } from 'notistack'
import { SignerContacts } from '../models/signer.contact'
import AppAsyncSelect from '../component/select/appAsyncSelect'
import { Confirm } from '../component/confirm'

class DocumentPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super();
        this.state = {
            isLoaded: false,
            loading: true,
            id: parseInt(props.match?.params.id) || 0,
            status: DocumentStatus.draft,
            responsible: null,
            deal: null,
            signer: null,
            signerCounterparty: [null],
            errors: {},
            fileSelected: false,
            fileType: false,
            originalFile: false,
            additionalAgreementDocument: false,
            additionalAgreementDocumentNumber: '',
            additionalAgreementDocumentDate: new Date(),
            signatures: [],
            loadError: false,
            fileInputKey: Date.now(),
            storyKey: Date.now(),
            sendInProcess: 'N',
            accessToResponsibleChange: false,
            accessToSignerChange: false,
            openLinkMessage: false,
            linkMessageType: 'success',
            signerContacts: {},
            observers: [null],
            confirmProps: null,
        };
        this.history = React.createRef();
    }

    componentDidMount() {
        const _ = this;
        this.checkPlacement(function () {
            _.refreshData();
        });
    }

    async checkPlacement(callback) {
        const info = Rest.placementInfo();
        if (this.state.id > 0 || info.placement !== 'CRM_DEAL_DETAIL_TAB' || !info.options.ID) {
            callback();
            return;
        }
        try {
            const deals = await Rest.callMethod('crm.deal.list', { filter: { ID: info.options.ID } });
            if (deals.items.length < 1) {
                callback();
            }
            else {
                const deal = new Deal(deals.items[0]);
                this.setState({
                    deal: {
                        value: deal.Id,
                        label: deal.Name
                    }
                }, () => {
                    callback();
                });
                this.tryFillResponsible(deal.ResponsibleId);
                this.tryFillContact(deal.ContactId);
            }
        }
        catch (err) {
            console.error('checkPlacement crm.deal.list', err);
            callback();
        }
    }

    refreshData = async () => {
        const _ = this;
        if (_.state.id < 1) {
            _.setState({
                loading: false
            }, () => {
                _.refreshDataEnd();
            });
            return;
        }

        _.setState({
            loading: true
        });

        const documents = await Rest.getDocuments([_.state.id]);
        const entity = documents[_.state.id];
        if (!entity) {
            _.setState({
                loading: false,
                loadError: true
            }, () => {
                _.refreshDataEnd();
            });
            return;
        }
        const files = await Rest.getDealFiles([entity.OriginalFileId, entity.SignedFileId, entity.ReportFileId, entity.AgreementFileId]);

        if (files[entity.OriginalFileId]) {
            entity.setOriginalFileObject(files[entity.OriginalFileId]);
        }
        if (files[entity.SignedFileId]) {
            entity.setSignedFileObject(files[entity.SignedFileId]);
        }
        if (files[entity.ReportFileId]) {
            entity.setReportFileObject(files[entity.ReportFileId]);
        }
        if (files[entity.AgreementFileId]) {
            entity.setAgreementFileObject(files[entity.AgreementFileId]);
        }
        const observers = entity.ObserverList
        const users = await Rest.getUsers([entity.ResponsibleId, entity.SignerId, entity.SignerCounterpartyId, ...observers]);
        const deals = await Rest.getDeals([entity.DealId]);
        const counterparties = entity.SignerCounterpartiesList;
        const contacts = await Rest.getContacts(counterparties);

        const additionalAgreementDocumentNumber = entity.AdditionalAgreementDocumentNumber;
        const additionalAgreementDocumentDate = entity.AdditionalAgreementDocumentDate;
        const signerContacts = entity.SignerContacts;
        _.setState({
            status: entity.Status,
            responsible: entity.ResponsibleId > 0 && users[entity.ResponsibleId] ? { value: entity.ResponsibleId, label: users[entity.ResponsibleId].FullName } : null,
            deal: entity.DealId > 0 && deals[entity.DealId] ? { value: entity.DealId, label: deals[entity.DealId].Name } : false,
            signer: entity.SignerId > 0 && users[entity.SignerId] ? { value: entity.SignerId, label: users[entity.SignerId].FullName } : null,
            signerCounterparty: counterparties.filter((item) => !!contacts[item]).map((item) => ({ value: item, label: contacts[item].FullName, email: contacts[item].getContactByType(signerContacts[contacts[item].Id]), type: signerContacts[contacts[item].Id] })),
            signatures: entity.Signatures,
            originalFile: entity.OriginalFile,
            signedFile: entity.SignedFile,
            reportFile: entity.ReportFile,
            agreementFile: entity.AgreementFile,
            additionalAgreementDocument: !entity.AutoComplete && additionalAgreementDocumentNumber.length > 0 && additionalAgreementDocumentDate,
            additionalAgreementDocumentNumber: additionalAgreementDocumentNumber,
            additionalAgreementDocumentDate: additionalAgreementDocumentDate,
            fileSelected: entity.originalFileObject ?? false,
            fileType: entity.OriginalFileType,
            loading: false,
            sendInProcess: 'N',
            transactionId: entity.transactionId,
            signerStatuses: entity.SignerStatuses,
            signerContacts,
            autoComplete: entity.AutoComplete,
            observers: observers.filter((item) => !!users[item]).map((item) => ({ value: item, label: users[item].FullName })),
        }, () => {
            _.refreshDataEnd();
        });
    }

    async refreshDataEnd() {
        const currentUser = this.context.getCurrentUser();
        const userSignData = await Rest.getUserSignData(currentUser.Id);
        const accessSettings = this.context.access(AccessKey.settings);
        var newState = {
            accessToResponsibleChange: accessSettings || userSignData,
            accessToSignerChange: accessSettings || !userSignData,
            isLoaded: true
        };

        if (!this.isReadonly()) {
            if (!newState.accessToResponsibleChange && !this.state.responsible) {
                this.onResposibleChange({ value: currentUser.Id, label: currentUser.FullName });
            }
            if (!newState.accessToSignerChange && !this.state.signer) {
                this.onSignerChange({ value: currentUser.Id, label: currentUser.FullName });
            }
            if (this.state.fileType === FileType.local && this.state.originalFile?.link) {
                newState.fileSelected = `/document/originalFile/${Rest.getDomain()}/${this.state.id}`;
            }
        }

        this.setState(newState, () => {
            Rest.resizeFrame();
        });
        this.history.current?.refreshData();
    }

    onResposibleChange = async (selected) => {
        const _ = this;
        _.setState({
            responsible: selected
        }, async () => {
            await _.validateResponsible();
            await _.validateSigner();
        });
    }

    onDealChange = (selected) => {
        this.setState({
            deal: selected
        });


        if (this.state.fileType === FileType.deal) {
            this.setState({
                fileSelected: false,
                fileType: false
            });
        }

        if (selected && selected.object) {
            this.tryFillResponsible(selected.object.ResponsibleId);
            this.tryFillContact(selected.object.ContactId);
        }
    }

    onSignerChange = (selected) => {
        const _ = this;
        _.setState({
            signer: selected
        }, () => {
            _.validateSigner();
        });
    }

    async tryFillResponsible(id) {
        if (id < 1) {
            return;
        }
        if (this.state.responsible) {
            return;
        }
        if (this.isReadonly()) {
            return;
        }
        const entities = await Rest.getUsers([id]);
        if (entities[id]) {
            this.onResposibleChange({ value: entities[id].Id, label: entities[id].FullName });
        }
    }

    async tryFillContact(id) {
        if (id < 1) {
            return;
        }
        if (this.isReadonly()) {
            return;
        }
        const entities = await Rest.getContacts([id]);
        if (entities[id]) {
            this.onSignerCounterpartyChange({ value: entities[id].Id, label: entities[id].FullName }, 0);
        }
    }

    async validateResponsible() {
        const errors = this.state.errors;
        if (!this.state.responsible) {
            errors['responsible'] = [];
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
            return;
        }
    }

    async validateSigner() {
        if (!this.state.signer) {
            const errors = this.state.errors;
            errors['signer'] = [];
            if (errors['responsible'] && errors['responsible'].some(x => x.message === 'msg-error-access-to-signer-denied')) {
                errors['responsible'] = [];
            }
            this.setState({
                errors: errors
            });
            return;
        }

        const entities = await Rest.getUsers([this.state.signer.value]);
        const entity = entities[this.state.signer.value];

        if (!entity) {
            return;
        }

        try {
            const errors = this.state.errors;
            const userSignData = await Rest.getUserSignData(entity.Id);
            errors['responsible'] = [];
            errors['signer'] = entity.validateAsSigner(userSignData);

            if (userSignData) {
                if (this.state.responsible?.value && !userSignData.AllResponsibleId.includes(this.state.responsible.value)) {//&& !this.context.access(AccessKey.settings)
                    errors['responsible'].push(new ValidationError('msg-error-access-to-signer-denied'));
                }
            }

            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
        catch (err) {
            console.error('validateSigner', err);
        }
    }

    onSignerCounterpartyChange = (selected, idx) => {
        const _ = this;
        _.state.signerCounterparty.splice(idx, 1, selected);
        _.setState({
            signerCounterparty: [..._.state.signerCounterparty],
        }, () => {
            _.validateSignerCounterparty();
        });
    }

    onObserverChange = (selected, idx) => {
        const _ = this;
        _.state.observers.splice(idx, 1, selected);
        _.setState({
            observers: [..._.state.observers],
        });
    }

    onAdditionalAgreementDocumentChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocument: e.target.checked,
            autoComplete: e.target.checked ? false : _.state.autoComplete,
        }, () => {
            Rest.resizeFrame();
        });
    }

    onAdditionalAgreementDocumentNumberChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocumentNumber: e.target.value
        }, () => {
            _.validateAdditionalAgreementDocumentNumberChange();
        });
    }

    validateAdditionalAgreementDocumentNumberChange() {
        const _ = this;
        if (!_.state.errors['additionalAgreementDocumentNumber'] || _.state.errors['additionalAgreementDocumentNumber'].length < 1)
            return;
        const errors = _.state.errors;
        errors['additionalAgreementDocumentNumber'] = [];
        _.setState({
            errors: errors
        });
    }

    onAdditionalAgreementDocumentDateChange = (e) => {
        const _ = this;
        _.setState({
            additionalAgreementDocumentDate: e.target.value && e.target.value.length > 0 ? new Date(e.target.value) : new Date()
        }, () => {
            _.validateAdditionalAgreementDocumentDateChange();
        });
    }

    validateAdditionalAgreementDocumentDateChange() {
        const _ = this;
        if (!_.state.errors['additionalAgreementDocumentDate'] || _.state.errors['additionalAgreementDocumentDate'].length < 1)
            return;
        const errors = _.state.errors;
        errors['additionalAgreementDocumentDate'] = [];
        _.setState({
            errors: errors
        });
    }

    async validateSignerCounterparty(isError = false) {
        const errors = this.state.errors;
        Object.keys(errors).forEach((key) => {
            if (key.startsWith('signerCounterparty')) {
                errors[key] = [];
            }
        });
        errors['signerCounterparty'] = [];

        if (!this.state.signerCounterparty?.length) {
            this.setState({
                errors: errors
            });
            return;
        }

        const entities = await Rest.getContacts(this.state.signerCounterparty.map((item) => item?.value).filter((item) => !isNaN(item)));

        for (let i = 0; i < this.state.signerCounterparty.length; i++) {
            if (entities[this.state.signerCounterparty[i]?.value]) {
                errors[`signerCounterparty_${i}`] = entities[this.state.signerCounterparty[i].value].validateAsSigner(this.state.signerContacts[this.state.signerCounterparty[i].value], isError);
                if (!errors[`signerCounterparty_${i}`].length && !this.state.signatures.some((item) => item.actor === this.state.signerCounterparty[i].value)) {
                    errors[`signerCounterparty_${i}`] = [new ValidationError('signer-no-signature', { field: 'N', format: 'N', warning: 'Y' })];
                }
            } else if (this.state.signatures.some((item) => item.order === i + 2)) {
                errors[`signerCounterparty_${i}`] = [new ValidationError('signer-not-set', { field: 'N', format: 'N' })]
            }
        }
        this.setState({
            errors: errors
        }, () => {
            Rest.resizeFrame();
        });

        return !Object.values(errors).some((e) => e.some((err) => !err.isWarning));
    }

    clickRemoveOriginalFile = () => {
        this.setState({
            originalFile: false,
            fileSelected: false,
            signatures: []
        });
    }

    onSelectDealDocument = async (item) => {
        this.setState({
            fileSelected: item,
            fileType: FileType.deal,
            signatures: [],
            fileInputKey: Date.now()
        });
    }

    onFileRead = async (files) => {
        const file = files[0];
        //validate size limit?
        //size: 1
        //type: "application/pdf"
        const errors = this.state.errors;
        errors['file'] = [];
        if (!file) {
            this.setState({
                errors: errors,
                fileSelected: false
            }, () => {
                Rest.resizeFrame();
            });
            return;
        }
        if (!file || file.type !== 'application/pdf') {
            errors['file'].push(new ValidationError('msg-invalid-file-type'));
            this.setState({
                errors: errors,
                fileSelected: false
            }, () => {
                Rest.resizeFrame();
            });
            return;
        }
        this.setState({
            errors: errors,
            fileSelected: file,
            fileType: FileType.local,
            signatures: []
        }, () => {
            Rest.resizeFrame();
        });
    }

    async getRequestData(file) {
        const counterparties = this.state.signerCounterparty?.filter((item) => !isNaN(item?.value)).map((item) => item.value) ?? [];
        const observers = this.state.observers?.filter((item) => !isNaN(item?.value)).map((item) => `_${item.value}_`) ?? [];
        const signatures = [];
        for (const sig of this.state.signatures) {
            if (sig.order === 1 || counterparties.includes(sig.actor)) {
                signatures.push(sig);
            }
        }
        const result = {
            ENTITY: DataStorage.document,
            ID: this.state.id > 0 ? this.state.id : '',
            NAME: 'document',
            PROPERTY_VALUES: {
                RESPONSIBLE: this.state.responsible ? this.state.responsible.value : '',
                DEAL: this.state.deal ? this.state.deal.value : '',
                SIGNER: this.state.signer ? this.state.signer.value : '',
                SIGNER_COUNTERPARTIES: counterparties.join(';'),
                SIGNATURES: JSON.stringify(signatures),
                ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER: !this.state.autoComplete && this.state.additionalAgreementDocument ? this.state.additionalAgreementDocumentNumber : '',
                ADDITIONAL_AGREEMENT_DOCUMENT_DATE: !this.state.autoComplete && this.state.additionalAgreementDocument ? this.state.additionalAgreementDocumentDate.toISOString().slice(0, 10) : '',
                SIGNER_CONTACTS: JSON.stringify(this.state.signerContacts),
                AUTO_COMPLETE: this.state.autoComplete ? 'Y' : 'N',
                OBSERVERS: observers.join(';'),
            }
        };
        if (file && typeof file !== 'string') {
            if (this.state.fileType === FileType.local) {
                result.PROPERTY_VALUES.ORIGINAL_FILE_TYPE = FileType.local;
                result.PROPERTY_VALUES.ORIGINAL_FILE_NAME = file.name;
                result.PROPERTY_VALUES.ORIGINAL_FILE = [file.name, await Utils.convertFileBase64(file)];
            }
            if (this.state.fileType === FileType.deal) {
                result.PROPERTY_VALUES.ORIGINAL_FILE_TYPE = FileType.deal;
                result.PROPERTY_VALUES.ORIGINAL_FILE_NAME = file.title;
                result.PROPERTY_VALUES.ORIGINAL_FILE_ID = file.id;
            }
        }
        if (this.state.id < 1) {
            result.PROPERTY_VALUES.STATUS = DocumentStatus.draft;
        }
        return result;
    }

    validateFields() {
        const { errors } = this.state;

        const fields = [
            {
                name: 'signatures', checks: [
                    { check: this.state.signatures.length < 1, error: new ValidationError('field-signatures', { field: 'Y' }) }
                ]
            },
            {
                name: 'responsible', checks: [
                    { check: !this.state.responsible, error: new ValidationError('field-responsible', { field: 'Y' }) }
                ]
            },
            {
                name: 'signer', checks: [
                    { check: !this.state.signer, error: new ValidationError('field-signer', { field: 'Y' }) }
                ]
            },
            {
                name: 'signerCounterparty', checks: [
                    { check: !this.state.signerCounterparty.filter((item) => !isNaN(item?.value)).length, error: new ValidationError('field-signer-counterparty', { field: 'Y' }) },
                    { check: new Set(this.state.signerCounterparty.map((item) => item?.value).filter((item) => !isNaN(item))).size !== this.state.signerCounterparty.filter((item) => !isNaN(item?.value)).length, error: new ValidationError('signer-counterparty-duplicate', { field: 'N', format: 'N' }) }
                ]
            },
            {
                name: 'additionalAgreementDocumentNumber', checks: [
                    { check: this.state.additionalAgreementDocument && (!this.state.additionalAgreementDocumentNumber || this.state.additionalAgreementDocumentNumber.length < 1), error: new ValidationError('document-additional-agreement-document-number', { field: 'Y' }) }
                ]
            },
            {
                name: 'additionalAgreementDocumentDate', checks: [
                    { check: this.state.additionalAgreementDocument && !this.state.additionalAgreementDocumentDate, error: new ValidationError('document-additional-agreement-document-date', { field: 'Y' }) }//,
                    //{ check: this.state.additionalAgreementDocument && !moment(this.state.additionalAgreementDocumentDate, Document.AdditionalAgreementDocumentDateFormat, true).isValid(), error: new ValidationError('document-additional-agreement-document-date', { format: Document.AdditionalAgreementDocumentDateFormat }) }
                ]
            }
        ];

        const result = FieldValidator.validate(fields, errors);
        if (result.updateState) {
            this.setState({
                errors: errors
            }, () => {
                Rest.resizeFrame();
            });
        }
        return result.isValid;
    }

    saveAction(contactError = true) {
        const _ = this;
        return new Promise(async (resolve, reject) => {
            try {
                if (!_.validateFields(contactError)) {
                    resolve(false);
                    return;
                }
                const selectedFile = _.state.fileSelected ? this.state.fileSelected : false;
                const rd = await _.getRequestData(selectedFile);
                const result = await Rest.callMethod(`entity.item.${_.state.id > 0 ? 'update' : 'add'}`, rd);
                const currentUserId = _.context.getCurrentUser().Id;
                if (selectedFile) {
                    Rest.scrollParentWindow();
                }
                if (result.items.length > 0 && _.state.id < 1) {
                    new LogService(LogType.DocumentAdd, currentUserId, result.items[0], ObjectType.document).add();
                    _.setState({
                        id: result.items[0]
                    }, () => {
                        resolve(true);
                    });
                }
                else {
                    new LogService(LogType.DocumentEdit, currentUserId, _.state.id, ObjectType.document).add();
                    resolve(true);
                }
            }
            catch (err) {
                reject(err);
            }
        });
    }

    clickSave = async (e) => {
        if (e) e.preventDefault();

        try {
            const saveResult = await this.saveAction();
            if (!saveResult) {
                return;
            }
            this.refreshData();
        }
        catch (err) {
            console.log('save document err', err);
        }
    }

    clickSend = async (e) => {
        if (e) e.preventDefault();

        if (!await this.validateSignerCounterparty(true)) {
            return;
        }

        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            const saveResult = await this.saveAction(true);
            if (!saveResult) {
                return;
            }
            this.setState({
                sendInProcess: 'Y'
            });
            currentUserAuthData.documentId = this.state.id;
            const result = await axios.post('/document/send', currentUserAuthData);//'/test' '/document/send'
            const data = result.data;
            this.setState({
                sendInProcess: 'done'
            });
            this.refreshData();
        }
        catch (err) {
            console.error('send document err', Utils.getError(err));
            this.setState({
                sendInProcess: 'error'
            });
        }
    }

    clickDelete = async (e) => {
        if (e) e.preventDefault();
        const title = 'confirm.deleteDocument.title';
        let description;
        if (this.state.status === DocumentStatus.emailComplitedDoc) {
            description = 'confirm.deleteDocument.signed.description';
        } else {
            description = 'confirm.deleteDocument.description';
        }
        this.setState({ confirmProps: { title, description, action: () => {
                    try {
                        Rest.callMethod('entity.item.delete', { ENTITY: DataStorage.document, ID: this.state.id });
                        new LogService(LogType.DocumentDelete, this.context.getCurrentUser().Id, this.state.id, ObjectType.document).add();
                        this.props.history.push('/');
                    }
                    catch (err) {
                        console.error('clickDelete document', err);
                    }
                } } });
    }

    clickUpdate = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({
            storyKey: Date.now()
        });
        this.refreshData();
    }

    clickSync = async (e) => {
        if (e) e.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        try {
            const result = await axios.post('/document/sync', { bxId: this.state.id, ...Rest.getAuth() });
            if (Number(result.data) > 0) {
                this.props.enqueueSnackbar(i18n.t('msg-sync-success'), { variant: 'success' });
                this.refreshData();
            } else {
                this.props.enqueueSnackbar(i18n.t('msg-sync-empty'), { variant: 'success' });
            }
        }
        catch (err) {
            console.error('sync document err', Utils.getError(err));
            this.props.enqueueSnackbar(i18n.t(err.response?.data?.message ?? 'msg-error-sync-failure'), { variant: 'error' });
        } finally {
            this.setState({ loading: false });
        }
    }

    clickRevoke = async (e) => {
        if (e) e.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ confirmProps: {
                title: 'confirm.revokeDocument.title',
                description: 'confirm.revokeDocument.description',
                action: async () => {
                    this.setState({ loading: true });

                    try {
                        const result = await axios.post(`/document/revoke/${this.state.transactionId}`, { ...Rest.getAuth() });
                        this.props.enqueueSnackbar(i18n.t('msg-revoke-success'), { variant: 'success' });
                        await new Promise((resolve) =>  setTimeout(async () => {
                            await this.refreshData();
                            resolve();
                        }, 1000));
                    }
                    catch (err) {
                        console.error('revoke document err', Utils.getError(err));
                        this.props.enqueueSnackbar(i18n.t(err.response?.data?.message ?? 'msg-error-revoke-failure'), { variant: 'error' });
                    } finally {
                        this.setState({ loading: false });
                    }
                } } });
    }

    setSignatures = (signatures) => {
        const { errors } = this.state;
        errors['signatures'] = [];
        this.setState({
            signatures: signatures,
            errors: errors
        }, () => {
            this.validateSignerCounterparty();
        });
    }

    isReadonly() {
        return this.state.status !== DocumentStatus.draft || this.isObserver();
    }

    removeSignerCounterparty(idx) {
        this.setState((prev) => {
            prev.signerCounterparty.splice(idx, 1);
            return {
                signerCounterparty: [...prev.signerCounterparty],
                signatures: this.state.signatures.filter((item) => item.order !== idx + 2),
            };
        })
    }

    removeObserver(idx) {
        this.setState((prev) => {
            prev.observers.splice(idx, 1);
            return { observers: [...prev.observers] };
        })
    }

    docLinkAllowed(type) {
        switch (type) {
            case SignerContacts.VK.id:
            case SignerContacts.TELEGRAM.id:
                return false;
            default:
                return true;
        }
    }

    async createLink(contactId, type) {
        try {
            const response = await axios.post('/document/createLink', {
                documentId: this.state.id,
                type,
                contactId,
                ...Rest.getAuth(),
            });
            const textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = 0;
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            textArea.value = response.data;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            if (document.execCommand('copy')) {
                this.props.enqueueSnackbar(i18n.t('msg-create-link-success'), { variant: 'success' });
            } else {
                this.props.enqueueSnackbar(i18n.t('msg-create-link-show', [response.data]), { variant: 'success', autoHideDuration: null });
            }
            document.body.removeChild(textArea);

        } catch (e) {
            console.error(e);
            this.props.enqueueSnackbar(i18n.t(e.response?.data?.message ?? 'msg-create-link-failure'), { variant: 'error' });
        }
    }

    clickResend = async (contactId, type) => {
        if (this.state.loading) {
            return;
        }

        const auth = Rest.getAuth();
        if (!auth) {
            return;
        }

        this.setState({ loading: true });

        try {
            await axios.post(`/document/resend/${this.state.transactionId}`, { ...auth, contactId, type });
            this.props.enqueueSnackbar(i18n.t('msg-resend-success'), { variant: 'success' });
        } catch (e) {
            console.error('resend emails err', Utils.getError(e));
            this.props.enqueueSnackbar(i18n.t(e.response?.data?.message ?? 'msg-resend-failure'), { variant: 'error' });
        } finally {
            this.setState({ loading: false });
        }
    }

    notSigned(status) {
        return status === DocumentStatus.awaitingSignatures || status === DocumentStatus.opened;
    }

    onAutoCompleteChange = (e) => {
        const _ = this;
        _.setState({
            autoComplete: e.target.checked,
            additionalAgreementDocument: e.target.checked ? false : _.state.additionalAgreementDocument,
        }, () => {
            Rest.resizeFrame();
        });
    }

    isObserver() {
        const currentUser = this.context.getCurrentUser();
        return this.state.id > 0 &&
          !this.context.access(AccessKey.settings) &&
          this.state.responsible?.value !== currentUser.Id &&
          this.state.signer?.value !== currentUser.Id;
    }

    render() {
        if (this.loading && !this.isLoaded) {
            return (
                <div>
                    <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                    <Loader />
                </div>
            );
        }
        if (this.state.loadError) {
            return (
                <div>
                    <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                    <div class="alert alert-danger d-inline-block" role="alert" data-mdb-color="danger">
                        <Trans>msg-document-not-found</Trans>
                    </div>
                </div>
            );
        }
        const isReadonly = this.isReadonly();
        return (
            <div>
                {this.state.confirmProps != null && <Confirm {...this.state.confirmProps}/>}
                <DocumentPageTitle id={this.state.id} status={this.state.status} deal={this.state.deal} />
                <div className="row">
                    <div className="col-lg-8 col-xl-8 col-xxl-9">
                        <form>
                            {((!isReadonly) || (isReadonly && this.state.deal)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly &&
                                            <div className="form-outline">
                                                <DealSelect value={this.state.deal} onChange={this.onDealChange} title="field-deal" />
                                            </div>
                                        }
                                        {isReadonly && this.state.deal &&
                                            <>
                                                <div><Trans>field-deal</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/crm/deal/details/${this.state.deal.value}/`} target="_blank">{this.state.deal.label}</a>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {!this.state.originalFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-lg-6 col-xxl-4">
                                        <Dropzone onDrop={this.onFileRead}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <div {...getRootProps()} className="file-drop">
                                                    <input {...getInputProps()} accept="application/pdf" />
                                                    <div>
                                                        <i className="fas fa-cloud-upload-alt fa-3x"></i>
                                                    </div>
                                                    <div className="text-center">
                                                        {!isDragActive
                                                            ? <Trans>document-select-file-drag-text</Trans>
                                                            : <Trans>document-select-file-drop-text</Trans>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div>
                                            <FieldErrors errors={this.state.errors['file']} />
                                        </div>
                                    </div>
                                    {this.state.deal &&
                                        <DealFileSelect dealId={this.state.deal.value} fileSelected={this.state.fileType === FileType.deal ? this.state.fileSelected : false} onSelectDocument={this.onSelectDealDocument} />
                                    }
                                </div>
                            }
                            {this.state.originalFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <a href={this.state.originalFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-original-file</Trans></a>
                                            </div>
                                            {!isReadonly &&
                                                <div>
                                                    <button className="btn btn-outline-danger px-3" onClick={this.clickRemoveOriginalFile}>
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.signedFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.signedFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-signed-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.agreementFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.agreementFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-agreement-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.reportFile &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        <a href={this.state.reportFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-report-file</Trans></a>
                                    </div>
                                </div>
                            }
                            {this.state.fileSelected && !isReadonly &&
                                <div className="mt-4">
                                    <SignPosition file={this.state.fileType === FileType.deal ? `/document/proxy?q=${encodeURIComponent(this.state.fileSelected.pdfUrlMachine)}` : this.state.fileSelected} counterparties={this.state.signerCounterparty} signatures={this.state.signatures} setSignatures={this.setSignatures} />
                                </div>
                            }
                            {!isReadonly &&
                                <div>
                                    <FieldErrors errors={this.state.errors['signatures']} />
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.responsible)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly && this.state.accessToResponsibleChange &&
                                            <div className="form-outline">
                                                <UserSelect value={this.state.responsible} onChange={this.onResposibleChange} title="field-sign-responsible" />
                                                <FieldErrors errors={this.state.errors['responsible']} />
                                            </div>
                                        }
                                        {(isReadonly || !this.state.accessToResponsibleChange) && this.state.responsible &&
                                            <>
                                                <div><Trans>field-sign-responsible</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/company/personal/user/${this.state.responsible.value}/`} target="_blank">{this.state.responsible.label}</a>
                                                </div>
                                                <FieldErrors errors={this.state.errors['responsible']} />
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {(!isReadonly || (isReadonly && this.state.signer)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly && this.state.accessToSignerChange &&
                                            <div className="form-outline">
                                                <UserSelect value={this.state.signer} onChange={this.onSignerChange} title="field-signer" />
                                                <FieldErrors errors={this.state.errors['signer']} />
                                            </div>
                                        }
                                        {(isReadonly || !this.state.accessToSignerChange) && this.state.signer &&
                                            <>
                                                <div><Trans>field-signer</Trans></div>
                                                <div>
                                                    <a href={`https://${Rest.getDomain()}/company/personal/user/${this.state.signer.value}/`} target="_blank">{this.state.signer.label}</a>
                                                </div>
                                                <FieldErrors errors={this.state.errors['signer']} />
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.observers?.length > 0)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly &&
                                            <>
                                                {this.state.observers.map((observer, idx) => (<>
                                                  <div className={`d-flex ${idx > 0 ? 'mt-3' : ''}`}>
                                                        <div className="row m-0 flex-grow-1">
                                                            <div className="form-outline ps-0">
                                                                <UserSelect value={observer} onChange={(selected) => this.onObserverChange(selected, idx)} exclude={this.state.observers?.map((item) => item?.value)} title="field-observer" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a className="btn btn-outline-danger px-3 h-100 ms-1" href="#" onClick={() => this.removeObserver(idx)}>
                                                                <i className="fas fa-trash"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>))}
                                                <a href="#" onClick={() => this.setState((prev) => ({
                                                    observers: [...prev.observers, null],
                                                }))}><Trans>btn-add-observer</Trans></a>
                                            </>
                                        }
                                        {isReadonly && this.state.observers?.length > 0 &&
                                            <>
                                                <div><Trans>field-observer</Trans></div>
                                                {this.state.observers.filter((o) => !!o).map((observer, idx) => (<div key={idx}>
                                                    <a href={`https://${Rest.getDomain()}/company/personal/user/${observer.value}/`} target="_blank">{observer.label}</a>
                                                </div>))}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.signerCounterparty?.length > 0)) &&
                                <div className="row mt-4">
                                    <div className="col-12 col-xl-8">
                                        {!isReadonly &&
                                            <>
                                                {this.state.signerCounterparty.map((counterparty, idx) => (<>
                                                  <div className={`d-flex ${idx > 0 ? 'mt-3' : ''}`}>
                                                        <div className="row m-0 flex-grow-1">
                                                            <div className="form-outline ps-0 col-9">
                                                                <ContactSelect value={counterparty} exclude={this.state.signerCounterparty?.map((item) => item?.value)} onChange={(selected) => this.onSignerCounterpartyChange(selected, idx)} title="field-signer-counterparty" />
                                                            </div>
                                                            <div className="form-outline ps-0 col-3">
                                                                <AppAsyncSelect className="react-select" title="field-signer-contact"
                                                                                isDisabled={!counterparty?.value}
                                                                                value={counterparty?.type ? { value: counterparty.type, label:  i18n.t(SignerContacts[counterparty.type.toUpperCase()].labelCode) } : null}
                                                                                onChange={(selected) => {
                                                                                    this.setState({ signerContacts: { ...this.state.signerContacts, [counterparty?.value ?? -1]: selected.value } }, () => this.validateSignerCounterparty());
                                                                                    this.onSignerCounterpartyChange({ ...counterparty, type: selected.value }, idx);
                                                                                }}
                                                                                options={Object.values(SignerContacts).map((c) => ({ value: c.id, label: i18n.t(c.labelCode) }))} />
                                                            </div>
                                                        </div>
                                                        {this.state.fileSelected && <div>
                                                            <a className="btn btn-outline-danger px-3 h-100 ms-1" href="#" onClick={() => this.removeSignerCounterparty(idx)}>
                                                                <i className="fas fa-trash"></i>
                                                            </a>
                                                        </div>}
                                                        <div>
                                                            <a className="btn btn-outline-success px-3 h-100 ms-1" href={`https://${Rest.getDomain()}/crm/contact/details/0/`} target="_blank">
                                                                <i className="fas fa-plus"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <FieldErrors errors={this.state.errors[`signerCounterparty_${idx}`]} />
                                                </>))}
                                                <a href="#" onClick={() => this.setState((prev) => ({
                                                    signerCounterparty: [...prev.signerCounterparty, null],
                                                }))}><Trans>btn-add-counterparty</Trans></a>
                                                <FieldErrors errors={this.state.errors[`signerCounterparty`]} />
                                            </>
                                        }
                                        {isReadonly && this.state.signerCounterparty?.length > 0 &&
                                            <>
                                                <div><Trans>field-signer-counterparty</Trans></div>
                                                {this.state.signerCounterparty.filter((cp) => !!cp).map((counterparty, idx) => (<div key={idx}>
                                                    <a className={Utils.getSignerStatusClass(this.state.signerStatuses[counterparty.email], 'text-muted', '')} href={`https://${Rest.getDomain()}/crm/contact/details/${counterparty.value}/`} target="_blank">
                                                        {counterparty.label} {this.state.signerStatuses[counterparty.email] && <>(<Trans>signer-status-{this.state.signerStatuses[counterparty.email]}</Trans>)</>}
                                                    </a>
                                                    {this.state.transactionId && this.notSigned(this.state.signerStatuses[counterparty.email]) && <>
                                                      {this.docLinkAllowed(counterparty.type) && <MDBTooltip tag="a" wrapperProps={{ href: '#', onClick: () => this.createLink(counterparty.value, counterparty.type), className: 'ms-2' }} placement='right' className="ms-2" title={i18n.t('document-sign-link-tooltip')}>
                                                            <i className="fas fa-link"></i>
                                                        </MDBTooltip>}
                                                        <MDBTooltip tag="a" wrapperProps={{ href: '#', onClick: () => this.clickResend(counterparty.value, counterparty.type), className: 'ms-2' }} placement='right' className="ms-2" title={i18n.t('btn-resend')}>
                                                            <i className="fa fa-bell"></i>
                                                        </MDBTooltip>
                                                    </>}
                                                </div>))}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {!isReadonly &&
                              <div className="col-12 col-xl-8 mt-3">
                                  <div className="mx-1">
                                      <MDBCheckbox id="autoComplete" checked={this.state.autoComplete} onChange={this.onAutoCompleteChange} label={i18next.t('document-autoComplete')} />
                                  </div>
                              </div>
                            }
                            {((!isReadonly) || (isReadonly && this.state.additionalAgreementDocument)) &&
                                <div className="row mt-4">
                                    {!isReadonly &&
                                        <div className="col-12 col-xl-8">
                                            <div className="mx-1">
                                                <MDBCheckbox id="additionalAgreementDocument" checked={this.state.additionalAgreementDocument} onChange={this.onAdditionalAgreementDocumentChange} label={i18next.t('document-additional-agreement-document')} />
                                            </div>
                                        </div>
                                    }
                                    {!isReadonly && this.state.additionalAgreementDocument &&
                                        <>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <div><Trans>document-additional-agreement-document-description</Trans></div>
                                            </div>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <MDBInput type="text" value={this.state.additionalAgreementDocumentNumber} onChange={this.onAdditionalAgreementDocumentNumberChange} label={i18next.t('document-additional-agreement-document-number')} required />
                                                <FieldErrors errors={this.state.errors['additionalAgreementDocumentNumber']} />
                                            </div>
                                            <div className="col-12 col-xl-8 mt-4">
                                                <MDBInput type="date" max="2100-01-01" onChange={this.onAdditionalAgreementDocumentDateChange} value={`${this.state.additionalAgreementDocumentDate ? this.state.additionalAgreementDocumentDate.toISOString().slice(0, 10) : ''}`} label={i18next.t('document-additional-agreement-document-date')} required />
                                                <FieldErrors errors={this.state.errors['additionalAgreementDocumentDate']} />
                                            </div>
                                        </>
                                    }
                                    {isReadonly && this.state.additionalAgreementDocument &&
                                        <>
                                            <div className=""><Trans>document-additional-agreement-document-number</Trans></div>
                                            <div>{this.state.additionalAgreementDocumentNumber}</div>
                                            <div className="mt-4"><Trans>document-additional-agreement-document-date</Trans></div>
                                            <div>{this.state.additionalAgreementDocumentDate.toLocaleDateString()}</div>
                                        </>
                                    }
                                    <div className="col-12 col-xl-8 mt-4"><a href="https://avisclouddev.blob.core.windows.net/avissigndocuments/AdditionalAgreementAvisSIGN.pdf" target="_blank"></a></div>
                                </div>
                            }
                            {!this.loading && !this.isObserver() && <>
                                <div className="mt-4">
                                    {(this.state.status === DocumentStatus.draft || this.state.sendInProcess !== 'N') && this.state.id > 0 &&
                                        <button type="button" className={`btn ${this.state.sendInProcess === 'error' ? 'btn-danger' : 'btn-success'} me-4`} onClick={this.clickSend} disabled={this.state.sendInProcess !== 'N'}>
                                            <Trans>btn-send-sign</Trans>
                                            {this.state.sendInProcess === 'Y' &&
                                                <ButtonLoader />
                                            }
                                            {this.state.sendInProcess === 'done' &&
                                                <i className="fas fa-check ms-2"></i>
                                            }
                                            {this.state.sendInProcess === 'error' &&
                                                <i className="fas fa-exclamation ms-2"></i>
                                            }
                                        </button>
                                    }
                                    {this.state.status === DocumentStatus.draft &&
                                        <button type="button" className="btn btn-primary me-2" onClick={this.clickSave}><Trans>btn-save</Trans></button>
                                    }
                                    {this.state.id > 0 && this.state.transactionId && this.state.status !== DocumentStatus.draft && this.state.status !== DocumentStatus.sendError && this.state.status !== DocumentStatus.emailComplitedDoc && this.state.status !== DocumentStatus.cancelled && this.state.status !== DocumentStatus.completed &&
                                        <button type="button" className="btn btn-outline-danger me-2" onClick={this.clickRevoke}><Trans>btn-revoke</Trans></button>
                                    }
                                    {this.state.id > 0 && [DocumentStatus.draft, DocumentStatus.emailComplitedDoc, DocumentStatus.sendError, DocumentStatus.cancelled, DocumentStatus.denied, DocumentStatus.completed].includes(this.state.status) &&
                                        <button type="button" className="btn btn-outline-danger me-2" onClick={this.clickDelete}><Trans>btn-delete</Trans></button>
                                    }
                                    <Link to="/" className="btn btn-outline-default me-2"><Trans>btn-to-list</Trans></Link>
                                    {this.state.id > 0 && this.state.status !== DocumentStatus.emailComplitedDoc && this.state.status !== DocumentStatus.denied &&
                                        <button type="button" className="btn btn-outline-primary me-2" onClick={this.clickUpdate}>
                                            <Trans>btn-update</Trans>
                                            {this.state.loading &&
                                                <ButtonLoader />
                                            }
                                        </button>
                                    }
                                </div>
                                {this.state.id > 0 && this.state.transactionId && this.state.status !== DocumentStatus.draft && this.state.status !== DocumentStatus.denied && this.state.status !== DocumentStatus.cancelled &&
                                  <div className="mt-2">
                                      {this.state.status !== DocumentStatus.emailComplitedDoc &&
                                        <button type="button" className="btn btn-outline-default me-2" onClick={() => this.clickResend()}>
                                          <Trans>btn-resend</Trans>
                                          {this.state.loading &&
                                            <ButtonLoader />
                                          }
                                        </button>
                                      }
                                      {this.state.status !== DocumentStatus.sendError &&
                                        <MDBTooltip wrapperProps={{ color: 'warning', outline: true, onClick: this.clickSync }} className="ms-1" placement='right' title={i18n.t('document-sync-tooltip')}>
                                            <Trans>btn-sync</Trans>
                                            {this.state.loading &&
                                              <ButtonLoader />
                                            }
                                        </MDBTooltip>
                                      }
                                  </div>
                                }
                            </>}
                            {!this.loading && this.isObserver() && <div className="mt-4">
                                <Link to="/" className="btn btn-outline-default me-2"><Trans>btn-to-list</Trans></Link>
                            </div>}
                        </form>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-xxl-3 mt-3 mt-lg-0">
                        {this.state.id > 0 &&
                            <DocumentHistory ref={this.history}  id={this.state.id} key={this.state.storyKey} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class DocumentHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    async refreshData() {
        try {
            const requestData = {
                ENTITY: DataStorage.log,
                SORT: {
                    property_date: 'asc'
                },
                filter: {
                    property_object_id: [this.props.id],
                    property_object_type: [ObjectType.document]
                }
            };

            const result = await Rest.callMethod('entity.item.get', requestData, true);
            const items = result.items.map(x => new Log(x));
            const userIds = [];
            items.forEach((item) => {
                if (!userIds.includes(item.PROPERTY_VALUES.USER)) {
                    userIds.push(item.PROPERTY_VALUES.USER);
                }
            });
            const users = await Rest.getUsers(userIds);

            let lastStatus = false;
            items.forEach(item => {
                if (users[item.UserId]) {
                    item.User = users[item.UserId];
                }
                if (lastStatus) {
                    item.lastStatus = lastStatus;
                }
                if (item.Type === LogType.DocumentStatus) {
                    lastStatus = item.Description;
                }
            });

            items.reverse();

            if (items.length > 0) {
                await this.checkCurrentStatus(items[0]);
            }

            this.setState({
                items: items
            }, () => {
                Rest.resizeFrame();
            });
        }
        catch (err) {
            console.error('DocumentHistory', err);
        }
    }

    /**
     * Проверяет последний статус с истории и сверяет с текущим статусом документа. Если последний статус EmailComplitedDoc то обновлеяет текущий статус документа
     * @param {*} logItem 
     * @returns 
     */
    async checkCurrentStatus(logItem) {
        try {
            const lastStatus = logItem.Description;
            if (lastStatus !== DocumentStatus.emailComplitedDoc) {
                return;
            }
            const documents = await Rest.getDocuments([this.props.id]);
            const entity = documents[this.props.id];
            if (entity && entity.Status !== lastStatus) {
                const rd = {
                    ENTITY: DataStorage.document,
                    ID: entity.ID,
                    PROPERTY_VALUES: {
                        SIGN_DATE: logItem.PROPERTY_VALUES.DATE,
                        STATUS: logItem.Description
                    }
                };
                const result = await Rest.callMethod(`entity.item.update`, rd);
            }
        }
        catch (err) {
            console.warn('error checkCurrentStatus', err);
        }
    }

    getActorView(actor) {
        const [name, sourceId] = actor?.split(':');
        const source = SignerContacts[sourceId?.toUpperCase()]?.labelCode
        return <>{name} {source ? <>(<Trans>{source}</Trans>)</> : null}</>
    }

    render() {
        if (this.state.items.length < 1) {
            return (
                <></>
            );
        }
        return (
            <div className="document-history mt-4">
                {this.state.items.map(item => {
                    const description = item.Type === LogType.DocumentStatus ? (
                        <div className="document-history-statuses">
                            {item.lastStatus &&
                                <>
                                    <div className={Utils.getDocumentStatusClass(item.lastStatus)}><small><Trans>document-status-{item.lastStatus}</Trans></small></div>
                                    <div><i className="fas fa-long-arrow-alt-right mx-2 text-black-50"></i></div>
                                </>
                            }
                            <div className={Utils.getDocumentStatusClass(item.Description)}><small><Trans>document-status-{item.Description}</Trans></small></div>
                        </div>
                    ) : (<div>{item.Description}</div>);
                    return (
                        <div key={item.ID} className="mb-2 p-2 border">
                            {item.User &&
                                <div className="float-end">
                                    {item.User.LinkIconView}
                                </div>
                            }
                            <div><span className="fw-bolder me-2"><Trans>log-{item.Type}</Trans></span><span className="text-black-50">{item.Date.format('L LTS')}</span></div>
                            {description}
                            {item.Actor && <div><small><Trans>log-signer</Trans>: {this.getActorView(item.Actor)}</small></div>}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class DocumentPageTitle extends React.Component {
    render() {
        if (this.props.id < 1) {
            if (this.props.deal) {
                return (
                    <PageTitle text="document-page-title-new-deal" values={{ val: this.props.deal.label }} />
                );
            }
            return (
                <PageTitle text="document-page-title-new" />
            );
        }

        if (this.props.status === DocumentStatus.draft) {
            if (this.props.deal) {
                return (
                    <PageTitle text="document-page-title-edit-deal" values={{ val: this.props.deal.label }} />
                );
            }
            return (
                <PageTitle text="document-page-title-edit" />
            );
        }

        if (this.props.deal) {
            return (
                <PageTitle text="document-page-title-view-deal" values={{ val: this.props.deal.label }} />
            );
        }
        return (
            <PageTitle text="document-page-title-view" />
        );
    }
}

class DealFileSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            dealId: false,
            documents: []
        };
        this.delay = 500;
        this.loadTimestamp = 0;
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate() {
        if (this.state.dealId === this.props.dealId) {
            return;
        }
        this.refreshData();
    }

    async refreshData() {
        const _ = this;
        const now = new Date().getTime();
        _.loadTimestamp = now;

        setTimeout(() => {
            if (_.loadTimestamp !== now)
                return;
            _.loadDocuments();
        });
    }

    async loadDocuments() {
        try {
            const list = await Rest.callMethod('crm.documentgenerator.document.list', {
                filter: {
                    entityTypeId: 2,//deals
                    entityId: this.props.dealId
                }
            }, true);

            this.setState({
                documents: list.items.map(x => {
                    return { ...x, value: x.id, label: x.title }
                }),
                dealId: this.props.dealId
            }, () => {
                Rest.resizeFrame();
            });
            console.log('documents list', list);

        }
        catch (err) {
            console.error('loadDocuments', err);
            this.setState({
                documents: [],
                dealId: this.props.dealId
            }, () => {
                Rest.resizeFrame();
            });
        }
    }

    render() {
        if (this.state.documents.length < 1) {
            return (
                <></>
            );
        }
        const selected = this.props.fileSelected ? this.state.documents.find(x => x.id === this.props.fileSelected.id) : null;
        return (
            <div className="col-12 col-lg-6 col-xxl-4">
                <div className="d-flex mt-0 mt-lg-4">
                    <div className="me-3 mt-1"><Trans>document-select-file-or</Trans></div>
                    <div className="flex-grow-1">
                        <div className="form-outline">
                            <Select className="w-100" value={selected} onChange={this.props.onSelectDocument} options={this.state.documents} isClearable={true} placeholder=""></Select>
                            <label className={`select-label ${this.props.fileSelected ? 'active' : ''}`}><Trans>document-select-file-deal</Trans></label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(DocumentPage);